const christmas2024 = {
  headline: 'Upcoming Holidays will Impact Cash Out times',
  message: 'There will be no bank transfers Dec 25-27, Dec 31 and Jan 1st.',
};

const newyear2025 = {
  headline: 'Happy New Year!',
  message: 'Thank you for a great 2024. We look forward to serving you in 2025.',
};


const announcements = [
  newyear2025,
];

export default announcements;
