import React from 'react';
import { ExclamationCircleTwoTone } from '@ant-design/icons';  // Antd icon

const AnnouncementBanner = ({ headline, message }) => {
  return (
    <div className="container-fluid">
      <div 
        className="alert d-flex align-items-center" 
        role="alert"
        style={{
          backgroundColor: '#e3f2fd', // Lighter background (less yellow)
          border: '2px solid #ff009811', // Border color matching your theme
          borderRadius: '5px', // Optional: rounded corners
          padding: '15px 20px'
        }}
      >
        <ExclamationCircleTwoTone 
          style={{ fontSize: '2rem', marginRight: '15px' }}
          twoToneColor="#ff0098"  // Primary color
        />
        <div>
          <h4 className="mb-1" style={{ fontWeight: 'bold' }}>{headline}</h4>
          <p className="mb-0">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementBanner;
