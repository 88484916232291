import React from 'react';
import '../../App.scss';

const FooterCard = (props) => {

  // This component doesn't use any properties

    const footerStyle = {
      width: '100%',
      fontSize: 10,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    return (
      <div className="FooterCard">
        <div className="layoutFlow">
          
          <div className="elTermAndConditionsLinkCopy">
            <div className="font-tahoma" style={footerStyle}>
              <div>
                <a className="terms-and-conditions" href="https://www.strikepay.co/terms-of-service" target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{__html: props.locStrings.terms_and_conditions}}></a>
                <a className="terms-and-conditions" href="https://www.strikepay.co/privacy-policy" target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{__html: props.locStrings.privacy_policy}}></a>
                <br/>
                © { new Date().getFullYear() } Strike Technology Ltd
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
};

export default FooterCard;
