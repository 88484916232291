import React from 'react';

import announcements from '../../models/AnnouncementsStatic';

import AnnouncementBanner from './AnnouncementBanner';

const AnnouncementList = ({ announcements }) => {
  return (
    <div>
      {announcements && announcements.length > 0 &&
        announcements.map((announcement, index) => (
          <AnnouncementBanner key={`announcement-${index}`} {...announcement} />
        ))
      }
    </div>
  );
};

export const AnnouncementsCard = () => {
  return (
    <div>
      <AnnouncementList announcements={announcements} />
    </div>
  );
};

export default AnnouncementList;
