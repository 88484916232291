import React, { useState, useEffect } from 'react';

import TrackerService from '../../utils/TrackerService';

import { AutoStack } from './StackView';
import RedirectAndScrollTop from './RedirectAndScrollTop';


/*
 You could be due a tax refund! 
 Earning income and paying tax in Ireland? 
 Strikepay has partnered with Taxback to help you claim your tax refund. 
 Taxback's average Irish tax refund is €1,880 so it's well worth checking what you're owed! 
*/

// https://www.taxback.com/en/partner/fws-uk-paye-strikepay/?utm_ref=strikepay


const partnerIds = {
  'GB': 'fws-uk-paye-strikepay',
  'IE': 'ireland-registration',
  'UK': 'fws-uk-paye-strikepay',
  'US': null,
};


const reference = {
  'GB': 'strikepay',
  'IE': 'strikepayee',
  'UK': 'strikepay',
  'US': null,
}

const RenderImage = ({ imageUrl, style }) => {
  if (imageUrl && imageUrl.trim() !== '') {
    return <img src={imageUrl} alt="Banner Ad" style={style} />;
  }
  return <img src={'https://www.taxback.com/wp-content/themes/Taxback/assets/logos/tb_logo_red.svg'} alt='Logo' style={style} />;
};

const BannerAdStart = ({ imageUrl }) => {
  return (
    <>
      <RenderImage style={{ minWidth: '100px', maxHeight: '50px' }} imageUrl={imageUrl} />
    </>
  );

}

/**
 * 
 * 
Your FREE tax review is here!

Strikepay partnered with Taxback to help you claim your UK PAYE tax refund!

Strikepay users can avail of a free tax assessment and tax return filing when they simply fill in our short form.

Our tax specialists will complete a full tax review and ensure you are claiming all tax reliefs and credits you are entitled to.

Our Promise: You’ll get the Highest Legal Tax Refund!
 */

const BannerAdMiddleIE = ({ imageUrl }) => {
  return (
    <div style={{ marginLeft: '10px' }}>
      <h2>You could be due a tax refund!</h2>
      <p>Earning income and paying tax in Ireland? Strikepay has partnered with Taxback to help you claim your tax refund.</p>
      <p>Average Irish Tax Refund</p>
      <p style={{ color: 'darkred' }}><strong>€1,880</strong></p>
      <p>Your refund can be sent to your Strikepay account!</p>
    </div>
  );
}

const BannerAdMiddleGB = ({ imageUrl }) => {
  return (
    <div style={{ marginLeft: '10px' }}>
      <h2>Your FREE tax review is here!</h2>
      <p>Strikepay users can avail of a free tax assessment and tax return filing when they simply fill in our short form.</p>
      <p style={{ color: 'darkred' }}><strong>Our Promise: You’ll get the Highest Legal Tax Refund!</strong></p>
      <p>Your refund can be sent to your Strikepay account!</p>
    </div>
  );
}

const BannerAdMiddle = ({ imageUrl, country }) => {
  switch (country) {
    case 'IE':
      return <BannerAdMiddleIE imageUrl={imageUrl} />;
    case 'GB':
    case 'UK':
      return <BannerAdMiddleGB imageUrl={imageUrl} />;
    default:
      return null;
  }
}

const BannerAdEnd = ({ imageUrl }) => {
  return (
    <div style={{ marginLeft: '10px', textAlign: 'start' }}>
    </div>
  );
}

const BannerAd = ({ isAdmin, clientId, imageUrl, onDisplay, onClick, country }) => {
  const isLocalHost = window.location.hostname === 'localhost';
  const isTest = isLocalHost;
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const landingPage = partnerIds[country] || null;
  const ref = reference[country] || null;
  const url = `https://www.taxback.com/en/partner/${landingPage}/?utm_ref=${ref}&utm_content=${clientId}`;

  const showBannerAd = ref && landingPage && !isAdmin; // useFeatureFlagClientAds()

  const tracker = new TrackerService({
    isTest,
  });

  const data = {
    clientId,
    is_test: isTest,
    target: 'taxback',
    url,
    landing: landingPage || 'PAYE',
    displayName: 'Taxback Banner Ad',
    partnerClientId: 'taxback',
    partnerDisplayName: 'Taxback',
  }

  const onClickTrack = async () => {
    tracker.mixpanelTrack('partner_ad_clicked', data);

    if (!isTest) {
      tracker.googleTrack('partner_ad_clicked', data);
    }

  };

  const onDisplayTrack = async () => {
    if(!showBannerAd) return;

    tracker.mixpanelTrack('partner_ad_impression', data);

    if (!isTest) {
      tracker.googleTrack('partner_ad_impression', data);
    }
  };

  useEffect(() => {
    onDisplayTrack();

    if (typeof onDisplay === 'function') {
      onDisplay(data);
    }
  }, [onDisplay]);

  const onClickBanner = async () => {
    await onClickTrack();
    if (url) {
      setShouldRedirect(true);
    }

    if (typeof onClick === 'function') {
      onClick(data);
    }
    setShouldRedirect(false);
  };

  return showBannerAd ? (
    <div>
      {
        <RedirectAndScrollTop
          when={shouldRedirect}
          to={url}
        />
      }
      <h6 style={{ textAlign: 'start', padding: '0px', margin: '0px', color: '#ff0098' }}>
        ☑️ Strikepay trusted partners
      </h6>
      <a onClick={() => (onClickBanner())}>
        <div style={{ backgroundColor: '#EFE6DD' }}>
          <AutoStack
            contentStart={<BannerAdStart imageUrl={imageUrl} />}
            contentMiddle={<BannerAdMiddle country={country}/>}
            contentEnd={<BannerAdEnd />}
            style={{ marginTop: '2px', marginBottom: '2px', padding: '5px', border: '2px solid #ff0098' }}
          />
        </div>
        <h5 style={{ textAlign: 'end', padding: '0px', margin: '0px' }}>Click now to claim your refund</h5>
      </a>
    </div>
  ) : null;
};

export default BannerAd;
